import React from 'react'
import * as styles from './comic-cover-image.module.css'
import { randomFromSelection } from '../../utils/random'

import { GatsbyImage} from 'gatsby-plugin-image'

const ComicCoverImage = ({coverImage, coverImageAlt, coverImageTitle, description}) => { 

  const leftOrRight = randomFromSelection([styles.left, styles.right]);
  const topOrBottom = randomFromSelection([styles.top, styles.bottom]);
  const comicDescriptionClass = `${styles.comicImageDescription} ${leftOrRight} ${topOrBottom}`;

  return (<div className={styles.coverImageContainer}>
    <GatsbyImage
      className={styles.coverImageWrapper}
      imgClassName={styles.coverImage}
      image={coverImage}
      alt={coverImageAlt}
      title={coverImageTitle}
      quality={100}
      />
    {description && <div className={comicDescriptionClass}>{description}</div>}
  </div>
)}

export default ComicCoverImage;
