import React from 'react'
import * as styles from './YouTube.module.css'

export const YouTube = ({ video }) => (
  <section className={styles.videoWrapper}>
    <iframe
      className={styles.video}
      width="560"
      height="315"
      src={`https://www.youtube.com/embed/${video}`}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  </section>
)
