import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import get from 'lodash/get'

import Layout from '../components/layout/Layout'
import { ButtonPagination, TweetButton } from '../components/Button'
import Bio from '../components/bio/Bio'
import { InfoCallout, TweetCallout } from '../components/callout/Callout'
import Tag from '../components/tags/Tag'
import Newsletter from '../components/newsletter/Newsletter'
import ComicCoverImage from '../components/comic-cover-image/comic-cover-image'
import * as styles from './BlogPost.module.css'
import { getFormattedDate, toReadingTime } from '../utils/formatting'
import { YouTube } from '../components/YouTube'

import { rhythm } from '../utils/typography'
import { getImage } from 'gatsby-plugin-image'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const { previous, next } = this.props.pageContext
    const url = this.props.location.href

    const {
      date,
      dateModified,
      categories = [],
      description,
      keywords,
      cover_image,
      cover_image_alt: coverImageAlt,
      cover_image_title: coverImageTitle,
      video,
      published_draft_state: publishedDraftState,
    } = post.frontmatter
    const { excerpt, timeToRead } = post
    const formattedDate = getFormattedDate(date)
    const readingTime = toReadingTime(timeToRead)
    const [mainCategory] = categories || []
    const isPublishedDraft = !!publishedDraftState

    // prep image for social sharing
    // this social media image comes from the frontmatter social_image field
    // that is transformed into an image when querying GraphQL with childImageSharp
    // TODO: we should be able to get the size of the image here so we can appropriately
    // set the size in the generated HTML to improve CLS (content layout shift) and SEO
    // TODO: Read gatsby docs, I bet they have a solution for this already and
    // I'm just f***ing around with workarounds
    const socialImage = post.frontmatter.image
      ? post.frontmatter.image.childImageSharp.resize
      : null
    if (socialImage) socialImage.alt = coverImageAlt

    // Extract gatsbyImageData from ImageSharp node so that we can use it with the GatsbyImage
    // components that lets us use optimized images.
    const coverImage = getImage(cover_image)

    return (
      <Layout>
        <main>
          <article className={styles.article}>
            <SEO
              title={`${post.frontmatter.title} | ${siteTitle}`}
              description={description || excerpt}
              keywords={keywords}
              image={socialImage}
              pathname={this.props.location.pathname}
              date={date}
              dateModified={dateModified}
            />
            <header className={styles.header}>
              <time className={styles.date} style={{ display: 'none' }}>
                {formattedDate}
              </time>
              <span className={styles.readingtime}>{readingTime}</span>
              {mainCategory && (
                <Tag style={{ float: 'right' }} small>
                  {mainCategory}
                </Tag>
              )}
              {
                // TODO(vintharas): make this not behave like a category
                // right now since we reuse categories here we need to
                // mark all drafts as belonging to the category 'draft' :D
                // to not break how the tag component works. May be useful to
                // use a different type of tag
              }
              {isPublishedDraft && (
                <Tag style={{ float: 'right' }} small alert>
                  {publishedDraftState}
                </Tag>
              )}
              <h1 style={{ paddingBottom: rhythm(1) }}>
                {post.frontmatter.title}
              </h1>
            </header>
            {!video && coverImage && (
              <ComicCoverImage {...{coverImage, coverImageAlt, coverImageTitle, description}} />
            )}
            {video && <YouTube {...{ video }} />}
            <div
              dangerouslySetInnerHTML={{ __html: post.html }}
              className={styles.content}
            />
            <TweetButton
              url={url}
              title={post.frontmatter.title}
              tags={categories}
            />
            <hr style={{ marginBottom: rhythm(1) }} />
            <Bio />
            <hr style={{ marginBottom: rhythm(1) }} />
            <TweetCallout />
            {categories && (
              <InfoCallout
                style={{ marginBottom: rhythm(1) }}
                title="Tagged as..."
              >
                {categories.map((cat, idx) => (
                  <Tag key={idx}>{cat}</Tag>
                ))}
              </InfoCallout>
            )}
            <Newsletter
              theme="greenish"
              style={{
                boxShadow: '0px 1px 3px grey',
              }}
            />
          </article>
          <footer style={{ marginTop: rhythm(2) }}>
            {(previous || next) && (
              <h3 style={{ textAlign: 'center' }}>
                Previously in Barbarian Meets Coding...
              </h3>
            )}
            <ul className={styles.pagination}>
              {previous && (
                <li style={{ marginBottom: rhythm(1 / 2), flex: 1 }}>
                  <ButtonPagination to={previous.fields.slug} rel="prev">
                    ← {previous.frontmatter.title}
                  </ButtonPagination>
                </li>
              )}

              {next && (
                <li style={{ marginBottom: rhythm(1 / 2), flex: 1 }}>
                  <ButtonPagination to={next.fields.slug} rel="next">
                    {next.frontmatter.title} →
                  </ButtonPagination>
                </li>
              )}
            </ul>
          </footer>
        </main>
      </Layout>
    )
  }
}

export default BlogPostTemplate

// TODO(OPTIMIZE_BUILD): Review whether this query is relevant anymore
// I think that we're no longer using this query and only generating blog posts using
// ./gatsby-node.js and the graphql queries in ./queries
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      excerpt(pruneLength: 160)
      timeToRead
      frontmatter {
        title
        date
        date_modified
        categories
        description
        cover_image {
          childImageSharp {
            gatsbyImageData
          }
        }
        cover_image_alt
        cover_image_title
        video
        image: social_image {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
        published_draft_state
      }
    }
  }
`
